.u-center-text {
  text-align: center !important;
}

.u-margin-bottom-small {
  margin-bottom: 1.5rem !important;
}

.u-margin-bottom-medium {
  margin-bottom: 4rem !important;
}

.u-margin-bottom-big {
  margin-bottom: 8rem !important;
}

.u-margin-top-big {
  margin-top: 8rem !important;
}
.u-margin-top-huge {
  margin-top: 10rem !important;
}
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.music {
  background-color: $bg-color;
  grid-area: music;
  position: relative;
  .artist {
    height: 40rem;
    position: relative;
    &::before {
      content: "";
      display: block;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-image: linear-gradient(
        to right bottom,
        $color-grad-1,
        $color-grad-2
      );
      opacity: 0.6;
    }

    &__image {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
    }
  }

  &__details {
    display: flex;
    padding: 1rem 2rem;
    // for bringing it up, problem i .music__lyrics will come up too
    // position: absolute;
    // top: 32rem;
    flex-direction: row;
    align-items: end;
  }

  &__image {
    border-radius: 30%;
    width: 20rem;
    height: 20rem;
    object-fit: cover;
    box-shadow: -1rem -1rem 2rem -6px rgba(0, 0, 0, 0.2);
  }

  &__info {
    padding: 1rem 1rem;
    font-size: 1.7rem;
    color: $color-black;
    font-weight: 500;
    text-transform: capitalize;
    letter-spacing: 1px;

    & > :first-child {
      font-size: 5.2rem;

      font-weight: 700;
      letter-spacing: -3px;
    }
  }

  &__lyrics {
    background-color: $color-grey-light-2;
    color: $color-grey-dark-1;
    padding: 5rem 4rem;
    margin: 0 17rem;
    margin-bottom: 13rem;
    text-align: center;
    line-height: 4rem;
    letter-spacing: 1px;
    text-transform: capitalize;

    &--body {
      white-space: pre-line;
    }
  }
  &__copyright {
    display: inline-block;
    font-size: 1.3rem;
    // background-color: $color-primary;
    // color: $color-grey-light-2;
    font-weight: 700;
    // text-transform: uppercase;
    // line-height: 1.8rem;
  }

  &__image-pixel {
    display: none;
    width: 3rem;
    height: 3rem;
  }
}

.section-about {
  padding: 3rem 5rem;
}

.lyricsoligy-text-color {
  background-image: linear-gradient(to right, $color-grad-1, $color-grad-2);
  -webkit-background-clip: text;
  color: transparent;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  font-weight: 700;
}
.heading-primary {
  font-size: 3.5rem;
  letter-spacing: 0.2rem;
}
.heading-secondary {
  font-size: 2.6rem;
  font-weight: 700;
  text-transform: uppercase;
}
.paragraph {
  font-weight: 500;
  font-size: 1.6rem;
  &:not(:last-child) {
    margin-bottom: 2rem;
  }
}
