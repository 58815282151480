%btn {
  background-image: $gradient;
  border-radius: 10rem;
  border: none;
  text-transform: uppercase;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: all 0.3s;
  &:hover {
    transform: scale(1.1);
    box-shadow: 0 0rem 10rem rgba(61, 61, 61, 0.164);
  }

  &:focus {
    outline: none;
    transform: scale(1.05);
  }
}

.btn {
  @extend %btn;
  font-size: 1.5rem;
  padding: 1.5rem 3rem;
  font-weight: 600;
  svg {
    height: 2.25rem;
    width: 2.25rem;
    fill: currentColor;
  }
}

.btn--inline {
  color: $color-primary;
  font-size: 1.3rem;
  font-weight: 600;
  border: none;
  background-color: $color-grey-light-1;
  padding: 0.8rem 1.2rem;
  border-radius: 10rem;
  cursor: pointer;

  display: flex;
  align-items: center;
  transition: all 0.2s;

  svg {
    height: 1.6rem;
    width: 1.6rem;
    fill: currentColor;
    margin: 0 0.2rem;
  }

  span {
    margin: 0 0.4rem;
  }

  &:hover {
    color: $color-grad-2;
    background-color: $color-grey-light-2;
  }

  &:focus {
    outline: none;
  }
}

.message,
.error {
  max-width: 40rem;
  padding: 4rem 3rem;
  margin: 0 auto;
  display: flex;
  svg {
    width: 4.7rem;
    height: 4.7rem;
    color: $color-primary;
    transform: translateY(-0.3rem);
  }
  p {
    font-size: 1.8rem;
    margin-left: 2rem;
    line-height: 1.5;
    font-weight: 600;
    letter-spacing: 1px;
  }
}

.spinner {
  margin: 0 auto;
  width: 15rem;
  height: 8rem;
  --g1: conic-gradient(
    from 90deg at left 3px top 3px,
    #008080 90deg,
    #008080 0
  );
  --g2: conic-gradient(
    from -90deg at bottom 3px right 3px,
    #008080 90deg,
    #008080 0
  );

  background: var(--g1), var(--g1), var(--g1), var(--g2), var(--g2), var(--g2);
  background-position: left, center, right;
  background-repeat: no-repeat;
  animation: s9 1s infinite;
}

$spinner-size: 2rem;

@keyframes s9 {
  0% {
    background-size:
      $spinner-size 50%,
      $spinner-size 50%,
      $spinner-size 50%;
  }
  25% {
    background-size:
      $spinner-size 100%,
      $spinner-size 50%,
      $spinner-size 50%;
  }
  50% {
    background-size:
      $spinner-size 50%,
      $spinner-size 100%,
      $spinner-size 50%;
  }
  75% {
    background-size:
      $spinner-size 50%,
      $spinner-size 50%,
      $spinner-size 100%;
  }
  100% {
    background-size:
      $spinner-size 50%,
      $spinner-size 50%,
      $spinner-size 50%;
  }
}
