* {
  margin: 0;
  padding: 0;
}

*, :after, :before {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
}

@media only screen and (max-width: 61.25em) {
  html {
    font-size: 50%;
  }
}

body {
  color: #615551;
  background-image: linear-gradient(to bottom right, gold, teal);
  min-height: calc(100vh - 8vw);
  margin: 0;
  padding: 0;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  line-height: 1.6;
}

.container {
  background-color: #fff;
  border-radius: 10px;
  grid-template: "header header" 10rem
                 "list music" minmax(100rem, auto)
                 / 1fr 3fr;
  max-width: 120rem;
  min-height: 117rem;
  margin: 4vw auto;
  display: grid;
  overflow: hidden;
  box-shadow: 0 2rem 6rem .5rem #3d3d3d33;
}

@media only screen and (max-width: 78.15em) {
  .container {
    border-radius: 0;
    max-width: 100%;
    margin: 0;
  }
}

.svg-icon {
  stroke: currentColor;
  fill: none;
  stroke-width: 1.6px;
}

.search__btn, .btn {
  text-transform: uppercase;
  color: #fff;
  cursor: pointer;
  background-image: linear-gradient(to bottom right, gold, teal);
  border: none;
  border-radius: 10rem;
  align-items: center;
  transition: all .3s;
  display: flex;
}

.search__btn:hover, .btn:hover {
  transform: scale(1.1);
  box-shadow: 0 0 10rem #3d3d3d2a;
}

.search__btn:focus, .btn:focus {
  outline: none;
  transform: scale(1.05);
}

.btn {
  padding: 1.5rem 3rem;
  font-size: 1.5rem;
  font-weight: 600;
}

.btn svg {
  fill: currentColor;
  width: 2.25rem;
  height: 2.25rem;
}

.btn--inline {
  color: #007c7c;
  cursor: pointer;
  background-color: #f9f5f3;
  border: none;
  border-radius: 10rem;
  align-items: center;
  padding: .8rem 1.2rem;
  font-size: 1.3rem;
  font-weight: 600;
  transition: all .2s;
  display: flex;
}

.btn--inline svg {
  fill: currentColor;
  width: 1.6rem;
  height: 1.6rem;
  margin: 0 .2rem;
}

.btn--inline span {
  margin: 0 .4rem;
}

.btn--inline:hover {
  color: teal;
  background-color: #f2efee;
}

.btn--inline:focus {
  outline: none;
}

.message, .error {
  max-width: 40rem;
  margin: 0 auto;
  padding: 4rem 3rem;
  display: flex;
}

.message svg, .error svg {
  color: #007c7c;
  width: 4.7rem;
  height: 4.7rem;
  transform: translateY(-.3rem);
}

.message p, .error p {
  letter-spacing: 1px;
  margin-left: 2rem;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1.5;
}

.spinner {
  --g1: conic-gradient(from 90deg at left 3px top 3px, teal 90deg, teal 0);
  --g2: conic-gradient(from -90deg at bottom 3px right 3px, teal 90deg, teal 0);
  background: var(--g1), var(--g1), var(--g1), var(--g2), var(--g2), var(--g2);
  background-position: 0, center, 100%;
  background-repeat: no-repeat;
  width: 15rem;
  height: 8rem;
  margin: 0 auto;
  animation: 1s infinite s9;
}

@keyframes s9 {
  0% {
    background-size: 2rem 50%, 2rem 50%, 2rem 50%;
  }

  25% {
    background-size: 2rem 100%, 2rem 50%, 2rem 50%;
  }

  50% {
    background-size: 2rem 50%, 2rem 100%, 2rem 50%;
  }

  75% {
    background-size: 2rem 50%, 2rem 50%, 2rem 100%;
  }

  100% {
    background-size: 2rem 50%, 2rem 50%, 2rem 50%;
  }
}

.header {
  background-color: #eceaea;
  grid-area: header;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.header__logo {
  height: 4.5rem;
  margin-left: 3rem;
  display: block;
}

.search {
  background-color: #fff;
  border-radius: 3rem;
  align-items: center;
  padding-left: 3rem;
  transition: all .5s;
  display: flex;
}

.search:focus-within {
  transform: translateY(-.3rem);
  box-shadow: 0 .5rem 2rem #00000027;
}

.search__field {
  color: inherit;
  border: none;
  width: 30rem;
  font-family: inherit;
  font-size: 1.7rem;
  transition: width .5s;
}

.search__field:focus {
  outline: none;
}

.search__field::placeholder {
  color: #d3c7c3;
}

@media only screen and (max-width: 61.25em) {
  .search__field {
    width: auto;
  }

  .search__field::placeholder {
    color: #fff;
  }
}

.search svg {
  stroke-width: 2.8px;
  width: 2.4rem;
  height: 2.4rem;
}

.search__btn {
  padding: 1rem 2rem;
  font-family: inherit;
  font-weight: 400;
}

.nav {
  align-self: stretch;
  margin-right: 2rem;
}

.nav__list {
  height: 100%;
  list-style: none;
  display: flex;
}

.nav__item {
  position: relative;
}

.nav__btn {
  color: #918581;
  cursor: pointer;
  text-transform: uppercase;
  background: none;
  border: none;
  place-items: center;
  height: 100%;
  padding: 0 1.5rem;
  font-family: inherit;
  font-size: 1.6rem;
  font-weight: 700;
  transition: all .6s;
  display: flex;
}

.nav__btn svg {
  color: teal;
  width: 3rem;
  height: 3rem;
  margin-right: .7rem;
}

.nav__btn:hover {
  color: #615551;
  background-color: #f9f5f3;
}

.bookmarks {
  z-index: 10;
  visibility: hidden;
  opacity: 0;
  background-color: #fff;
  width: 40rem;
  padding: 1rem 0;
  transition: all .5s .2s;
  position: absolute;
  right: -5rem;
  box-shadow: .8rem .8rem 5rem 2rem #6155511a;
}

.bookmarks__list {
  list-style: none;
}

.bookmarks:hover, .nav__btn--bookmarks:hover + .bookmarks {
  visibility: visible;
  opacity: 1;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.preview {
  animation: .5s ease-in fadeIn;
}

.preview__link:link, .preview__link:visited {
  border-right: 1px solid #fff;
  align-items: center;
  padding: 1.5rem 3rem;
  text-decoration: none;
  transition: all .3s;
  display: flex;
  position: relative;
}

.preview__link:link:hover, .preview__link:visited:hover {
  background-color: #f2efee;
  transform: translateY(-2px);
}

.preview__data {
  grid-template-columns: 1fr 2rem;
  align-items: center;
  row-gap: 1rem;
  display: grid;
}

.preview__title {
  color: teal;
  text-transform: capitalize;
  text-overflow: ellipsis;
  white-space: nowrap;
  grid-column: 1 / -1;
  max-width: 20rem;
  font-size: 2rem;
  font-weight: 700;
  transition: all .8s ease-in-out;
  overflow: hidden;
}

.preview__artist-album {
  color: #918581;
  text-transform: capitalize;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 20rem;
  font-size: 1.5rem;
  font-weight: 500;
  transition: all .8s ease-in-out;
  overflow: hidden;
}

.preview__artist-album span {
  color: teal;
}

.search-results {
  flex-direction: column;
  padding: 3rem 0;
  display: flex;
}

.results {
  margin-bottom: 2rem;
  list-style: none;
}

.pagination {
  margin-top: auto;
  padding: 0 3.5rem;
}

.pagination:after {
  content: "";
  clear: both;
  display: table;
}

.pagination__btn--prev {
  float: left;
}

.pagination__btn--next {
  float: right;
}

.pagination .copyright {
  color: #918581;
  margin-top: 4rem;
  padding: 0 3.5rem;
  font-size: 1.2rem;
}

.u-center-text {
  text-align: center !important;
}

.u-margin-bottom-small {
  margin-bottom: 1.5rem !important;
}

.u-margin-bottom-medium {
  margin-bottom: 4rem !important;
}

.u-margin-bottom-big {
  margin-bottom: 8rem !important;
}

.u-margin-top-big {
  margin-top: 8rem !important;
}

.u-margin-top-huge {
  margin-top: 10rem !important;
}

.music {
  background-color: #eceaea;
  grid-area: music;
  position: relative;
}

.music .artist {
  height: 40rem;
  position: relative;
}

.music .artist:before {
  content: "";
  opacity: .6;
  background-image: linear-gradient(to bottom right, gold, teal);
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.music .artist__image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
}

.music__details {
  flex-direction: row;
  align-items: end;
  padding: 1rem 2rem;
  display: flex;
}

.music__image {
  object-fit: cover;
  border-radius: 30%;
  width: 20rem;
  height: 20rem;
  box-shadow: -1rem -1rem 2rem -6px #0003;
}

.music__info {
  color: #000;
  text-transform: capitalize;
  letter-spacing: 1px;
  padding: 1rem;
  font-size: 1.7rem;
  font-weight: 500;
}

.music__info > :first-child {
  letter-spacing: -3px;
  font-size: 5.2rem;
  font-weight: 700;
}

.music__lyrics {
  color: #615551;
  text-align: center;
  letter-spacing: 1px;
  text-transform: capitalize;
  background-color: #f2efee;
  margin: 0 17rem 13rem;
  padding: 5rem 4rem;
  line-height: 4rem;
}

.music__lyrics--body {
  white-space: pre-line;
}

.music__copyright {
  font-size: 1.3rem;
  font-weight: 700;
  display: inline-block;
}

.music__image-pixel {
  width: 3rem;
  height: 3rem;
  display: none;
}

.section-about {
  padding: 3rem 5rem;
}

.lyricsoligy-text-color {
  color: #0000;
  letter-spacing: .1rem;
  text-transform: uppercase;
  background-image: linear-gradient(to right, gold, teal);
  -webkit-background-clip: text;
  font-weight: 700;
}

.heading-primary {
  letter-spacing: .2rem;
  font-size: 3.5rem;
}

.heading-secondary {
  text-transform: uppercase;
  font-size: 2.6rem;
  font-weight: 700;
}

.paragraph {
  font-size: 1.6rem;
  font-weight: 500;
}

.paragraph:not(:last-child) {
  margin-bottom: 2rem;
}

.modal {
  border: none;
  border-radius: 9px;
  width: 100rem;
  padding: 3rem 6rem;
  animation: .5s fade-out;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 4rem 6rem #00000040;
}

.modal[open] {
  animation: .9s ease-out fade-in;
}

.modal[open]::backdrop {
  animation: .7s ease-out forwards backdrop-fade-in;
}

.modal__container {
  grid-template-columns: 1fr 1fr;
  gap: 0 3rem;
  display: grid;
}

.modal__column {
  gap: .5rem;
  display: grid;
}

.modal__column p {
  color: #615551;
  font-weight: 400;
  line-height: 3rem;
}

.modal__column li {
  margin: .7rem 3.5rem;
  list-style: outside;
}

.modal__heading {
  text-transform: uppercase;
  grid-column: 1 / -1;
  margin-bottom: 1rem;
  font-size: 2.7rem;
  font-weight: 700;
}

.modal__heading-secondary {
  text-transform: uppercase;
  font-size: 1.8rem;
  font-weight: 500;
}

.modal__btn-close {
  color: #007c7c;
  cursor: pointer;
  background: none;
  border: none;
  font-family: inherit;
  font-size: 4rem;
  position: absolute;
  top: .5rem;
  right: 1.6rem;
}

@keyframes fade-in {
  0% {
    opacity: 0;
    display: none;
  }

  100% {
    opacity: 1;
    display: block;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
    display: block;
  }

  100% {
    opacity: 0;
    display: none;
  }
}

@keyframes backdrop-fade-in {
  0% {
    -webkit-backdrop-filter: blur();
    backdrop-filter: blur();
  }

  100% {
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
  }
}

/*# sourceMappingURL=index.71d5e722.css.map */
